import { EnvironmentName } from './environment-types';

export const environment = {
  name: 'abhi' as EnvironmentName,
  production: true,
  maxUserInactivityMinutes: 15,
  isSaaSProject: true,
  rateLimiterHeader: '',
  paymentGraphQLUrl: 'https://payment.services.finverity.abhi.co/graphql',
  documentGraphQLUrl: 'https://document.services.finverity.abhi.co/graphql',
  notificationGraphQLUrl: 'https://notification.services.finverity.abhi.co/graphql',
  notificationWSGraphQLUrl: 'wss://notification.services.finverity.abhi.co/graphql',
  kycGraphQLUrl: 'https://kyc.services.finverity.abhi.co/graphql',
  actionLogGraphQlUrl: 'https://action-log.services.finverity.abhi.co/graphql',
  socketUrl: 'https://api.services.finverity.abhi.co/',
  serverUrl: 'https://api.services.finverity.abhi.co/',
  SSOUrl: 'https://sso.services.finverity.abhi.co/',
  platform: {
    title: 'Abhi Fintech Limited Admin Portal',
    company: 'Abhi Fintech Limited',
    logoUrl: 'assets/logo/logo_abhi.svg',
    saasSuffixLogoPath: 'assets/powered_by_logo.svg',
    faviconUrl: 'assets/favicon/favicon_abhi.ico',
  },
  currenciesUrl: 'https://payment.services.finverity.abhi.co/utils/currency-config',
  userDocumentsUrl: 'https://sso.services.finverity.abhi.co/utils/document-signs',
  businessProcessSettings: {
    isPayerPrepaymentActive: true,
  },
  userSnap: {
    enabled: true,
    GlobalAPIKey: '8d1ac1d2-2145-492b-9ad8-62f358f85d2d',
    ProjectAPIKey: 'e79ef9e3-a0f8-49bf-a3bd-6e86b318df09',
  },
  sentry: {
    enabled: false,
    dsn: '',
  },
};

export const AUTH_API_KEY = '@api-SSO';
export const THIRD_PARTY_API_KEY = '@api-third-party';
